import React, { useState, useEffect } from 'react'
import { Grow, Box, Grid, Typography, TextField, Button, Divider, ThemeProvider, useMediaQuery } from '@mui/material'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { CPaper, customColors, themeMain } from '../styles/themes';
import { useStateContext } from '../context/ContextProvider';
import { createRacun, getSetNextInvoiceNr, generatePdf, getRacun, getRacuniTop, deleteRacun } from '../actions/racuni';
import { calcNumOfZeros } from '../utils/index';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import * as moment from 'moment';
import IzbranoBlago from '../components/IzbranoBlago';
import { PartenerTexts } from '../styles/themes'
import Sum from '../components/Sum'
import { useNavigate, useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import Navbar from '../components/Navbar';
import IzberiBlago from './IzberiBlago'
import IzberiPartnerja from './IzberiPartnerja'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import DraftsIcon from '@mui/icons-material/Drafts';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import MailIcon from '@mui/icons-material/Mail';
import Tooltip from '@mui/material/Tooltip';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import { getPdf } from '../actions/pdf';

import SendToEmail from '../components/sendToEmail';
import { verifyUser } from '../actions/auth'

import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ArchiveIcon from '@mui/icons-material/Archive';


import { IoMdCloudOutline } from 'react-icons/io';


const Izdaja = () => {
    // eslint-disable-next-line

    const size = useMediaQuery('(max-width:600px)'); //phone 
    const { user, setUser, storitve, racunContext, setRacunContext, setStoritve, skupajDDV, skupajBrezDDV, setFinnalRacun, selectedPartner, setSelectedPartner, diffDDVs, zneski } = useStateContext();
    const [racun, setRacun] = useState(racunContext);
    const [numOfRacun, setNumOfRacun] = useState(0);
    const [stRacuna, setStRacuna] = useState(0);
    const [top10Rac, setTop10Rac] = useState([]);
    const [top10Draft, setTop10Draft] = useState([]);
    const [top10Invoice, setTop10Invoice] = useState([]);
    // const [periodicni, setPeriodicni] = useState(false);
    const [periodicniDatum, setPeriodicniDatum] = useState('2000-01-01');
    const [modeText, setModeText] = useState('Novi račun');
    const navigate = useNavigate();
    const sendToEmailRef = React.useRef(null);
    const izberiBlagoRef = React.useRef(null);
    const izberiPartnerjaRef = React.useRef(null);
    const [valueBottomNav, setValueBottomNav] = React.useState(0);
    const [miniBox, setMiniBox] = useState(false);

    const location = useLocation();
    const handleSubmit = async (e) => {

    };

    const f = (amount) => {
        if (amount)
            return amount.toFixed(2).replace('.', ',')
        else
            return '0,00'
    }

    const saveInvoice = async (asDraft) => {

        if (!asDraft) {
            if (storitve.length == 0) {
                toast.error('Niste izbrali storitve', {
                    iconTheme: {
                        primary: '#552600',
                        secondary: '#FFFAEE',
                    },
                    style: {
                        border: '1px solid #552600',
                        padding: '16px',
                        fontWeight: 600,
                        color: '#552600'
                    }
                });
                return;
            }
            else if (selectedPartner.naziv == '') {
                toast.error('Niste izbrali partnerja', {
                    iconTheme: {
                        primary: '#552600',
                        secondary: '#FFFAEE',
                    },
                    style: {
                        border: '1px solid #552600',
                        padding: '16px',
                        fontWeight: 600,
                        color: '#552600'
                    }
                });
                return;
            }
        }

        //fak
        const newArray = storitve.map(obj => {
            const newObj = { ...obj };
            newObj.cenaSkupaj = String((Number(newObj.cena.replace(',', '.')) * Number(newObj.kolicina.replace(',', '.'))).toFixed(2)).replace('.', ',')
            return newObj;
        });

        //const today = moment().format('DD.MM.YYYY');
        const racunFinnal = {
            ...racun,
            //stRacuna: stRacuna,
            partner: selectedPartner,
            datumIzdaje: racun.datumIzdaje,
            datumOprStoritve: racun.datumOprStoritve,
            // racun.datumOprStoritve === '' ? today() :
            rokPlacila: racun.rokPlacila,
            diffDDVs: Object.keys(zneski.ddvPovzetek).map(key => parseFloat(key).toFixed(2)), //diffDDVs,
            storitve: newArray,
            skupajZaPlacilo: f(zneski.znesekBruto), //skupajDDV.replace('€', '').slice(0, -1),
            skupajBrezDDV: f(zneski.znesekNeto),
            isDraft: asDraft,
            //nastavimo na backendu, tako ko t št računa.
            referenca: (racun.referenca.indexOf(stRacuna) > -1) ? '' : racun.referenca
        };
        console.log(racunFinnal);
        setFinnalRacun(racunFinnal);
        const { saved, message } = await createRacun(racunFinnal);
        if (saved) {
            if (!asDraft) {
                toast.success('Račun uspešno izdan', {
                    style: { padding: '16px', fontWeight: 600, }
                });
                generatePdf(saved._id);
                //setIdKreiranegaRacuna(saved._id);
                // handleClear();
                getSetNextInvoiceNr(new Date().getFullYear(), setStRacuna)
                //getRacuniCount(2024, setNumOfRacun);
            }
            else {
                toast.success('Račun shranjen med osnutke', {
                    style: { padding: '16px', fontWeight: 600, }
                });
            }

            getRacuniTop(10, 'draft').then(rac => {
                setTop10Draft(rac)

                if (asDraft && rac.length > 0)
                    itemSelect(rac[0])

            })
            getRacuniTop(10, 'invoice').then(rac => {
                setTop10Invoice(rac)

                if (!asDraft && rac.length > 0)
                    itemSelect(rac[0])
            })


        }
        else {
            showError('Napaka: ' + message)

        }
    }

    const showError = (error) => {

        toast.error(error, {
            iconTheme: {
                primary: '#552600',
                secondary: '#FFFAEE',
            },
            style: {
                border: '1px solid #552600',
                padding: '16px',
                fontWeight: 600,
                color: '#552600'
            }
        });

    }

    const handleAdd = (editIndex) => {

        if (racun.id && !racun.isDraft) return; // ne moreš na izdanem računu

        setRacunContext(racun);
        izberiBlagoRef.current.handleOpen(editIndex)
        /* if (editIndex == -1)
             navigate('/izberi-blago');
         else
             navigate('/izberi-blago/' + editIndex);*/
    };

    const handleAddPartner = () => {

        if (racun.id && !racun.isDraft) return; // ne moreš na izdanem računu

        setRacunContext(racun);
        izberiPartnerjaRef.current.handleOpen(1)
        //navigate('/partner');
    }

    const today = () => moment().format('YYYY-MM-DD');

    const handleClear = () => {
        let date = new Date().getFullYear().toString().slice(-2);
        setRacunContext({ stRacuna: '', datumIzdaje: today(), datumOprStoritve: today(), rokPlacila: today(), stNarocila: '', referenca: '', storitve: [], skupajZaPlacilo: '', skupajBrezDDV: '', dodatenText: '', periodni: false });
        setRacun({ stRacuna: '', datumIzdaje: today(), datumOprStoritve: today(), rokPlacila: today(), stNarocila: '', referenca: '', storitve: [], skupajZaPlacilo: '', skupajBrezDDV: '', dodatenText: '', periodni: false });
        setSelectedPartner({ naziv: '', naslov: '', mesto: '', posta: '', drzava: '', oznakaDrzave: '', TRRracun: '', davcnaStevilka: '' });
        setStoritve([]);
        //setPeriodicni(false)
    }
    useEffect(() => {
        console.log(location.search)
        const searchParams = new URLSearchParams(location.search);
        const formDataParamPName = searchParams.get('pName');
        const formDataParamS1Title = searchParams.get('s1Title');

        console.log(formDataParamPName)
        console.log(formDataParamS1Title)

        if(searchParams.get('m')== '1'){
            setMiniBox(true);
            console.log('minibox mode');
        }

        if (formDataParamPName){
            setSelectedPartner({exId: searchParams.get('pID'),  naziv: formDataParamPName, naslov: searchParams.get('pAddress'), mesto: searchParams.get('pCity'),posta: searchParams.get('pPostalCode'), davcnaStevilka: searchParams.get('pVat'),  })
        }

        if(formDataParamS1Title){
            const newStoritve = [];
            for (let ix = 1; ix < 6; ix++) {
                if(searchParams.get(`s${ix}Title`)){
                  //  setStoritve([...storitve, { naziv:searchParams.get(`s${ix}Title`), opis:'', enota:searchParams.get(`s${ix}Unit`), kolicina:searchParams.get(`s${ix}Qty`), cena:searchParams.get(`s${ix}Price`), stopnjaDDV:searchParams.get(`s${ix}VatRate`) }]);
                  newStoritve.push({ naziv:searchParams.get(`s${ix}Title`), opis:'', enota:searchParams.get(`s${ix}Unit`), kolicina:searchParams.get(`s${ix}Qty`), cena:searchParams.get(`s${ix}Price`), stopnjaDDV:searchParams.get(`s${ix}VatRate`) });
                }
            }
            setStoritve(newStoritve);
        }

        if(formDataParamPName || formDataParamS1Title){
            navigate('/izdaja');
        }

        


        verifyUser().then(res => {
            if (res)
                console.log('prijavljen')
            else {
                console.log('NI-si prijavljen')
                setUser(null)
                navigate('/');
            }

        })


        getSetNextInvoiceNr(new Date().getFullYear(), setStRacuna)
        // getRacuniCount(moment().format('YYYY'), setNumOfRacun);
        // eslint-disable-next-line react-hooks/exhaustive-deps
        /* console.log('getRacun test')
         getRacun('6606e78d05791bb937dfc173').then(rac => {
             console.log(rac)
         })*/
        console.log('getRacun test top 10')
        getRacuniTop(10, 'draft').then(rac => {
            setTop10Draft(rac)
            //console.log(rac)
        })
        getRacuniTop(10, 'invoice').then(rac => {
            setTop10Invoice(rac)
            //console.log(rac)
        })


        //

    }, []);

    useEffect(() => {
        console.log('racun.datumIzdaje SPRMENJEN')
        console.log(racun.datumIzdaje)
        if (racun.datumIzdaje) {
            const s = racun.datumIzdaje.split('-')
            if (s.length == 3) {
                if (s[0].length == 4 && s[0] > 2000) {
                    getSetNextInvoiceNr(s[0], setStRacuna)

                    var trenutniDatum = new Date(s[0], s[1] - 1, s[2]);
                    // Shrani trenutni mesec
                    var trenutniMesec = trenutniDatum.getMonth();
                    // Povečaj mesec za 1
                    trenutniDatum.setMonth(trenutniMesec + 1);
                    // Če je nov mesec krajši od trenutnega, nastavi na zadnji dan prejšnjega meseca
                    if (trenutniDatum.getMonth() !== (trenutniMesec + 1) % 12) {
                        trenutniDatum.setDate(0);
                    }
                    // Izpiši nov datum
                    setPeriodicniDatum(df(trenutniDatum))
                    console.log(trenutniDatum);
                }
            }
        }
    }, [racun.datumIzdaje]);

    /*useEffect(() => {
        let date = new Date().getFullYear().toString().slice(-2);
        setStRacuna(`${date}-${calcNumOfZeros(numOfRacun)}`);
        //   setRacunContext({ stRacuna:'', datumIzdaje:'', datumOprStoritve:'', rokPlacila:'', stNarocila:'6', storitve:[], skupajZaPlacilo:'', skupajBrezDDV:'' });
        // setRacun({ ...racun, referenca: `SI00 ${date}-${calcNumOfZeros(numOfRacun)}` });
    }, [numOfRacun]);*/

    const [formatedPartner, SetFormatedPartner] = useState('');
    useEffect(() => {
        if (selectedPartner.naziv && selectedPartner.naziv.length > 0) {
            let txt = selectedPartner.naziv + '\n' + selectedPartner.naslov;
            if (selectedPartner.davcnaStevilka && selectedPartner.davcnaStevilka.length > 0)
                txt += '\n' + selectedPartner.davcnaStevilka;
            SetFormatedPartner(txt);
        }
        else
            SetFormatedPartner(' ');
    }, [selectedPartner]);

    const itemSelect = (d) => {
        // handleClear()
        // console.log(racun)
        // console.log(d)
        //console.log('d.referenca')
        console.log(d)

        let date = new Date().getFullYear().toString().slice(-2);
        //if (!d.referenca || d.referenca == "") d.referenca = `SI00 ${stRacuna}`;
        setSelectedPartner(d.partner)
        setStoritve(d.storitve)
        // setPeriodicni(false)
        let newInv = { creator: d.creator, stRacuna: d.stRacuna, id: d._id, isDraft: d.isDraft, referenca: d.referenca, stNarocila: d.stNarocila, dodatenText: d.dodatenText, datumOprStoritve: d.datumOprStoritve, rokPlacila: d.rokPlacila, periodni: d.periodni }

        //če je zaključen/potrjen ali če je samodejno zgeneriran/periodni
        if (!d.isDraft && d._id || d.semPeriodni)
            newInv.datumIzdaje = d.datumIzdaje
        else
            newInv.datumIzdaje = today()

        if (newInv.periodni == null)
            newInv.periodni = false

        //console.log(newInv)
        setRacun(newInv);
    }
    const itemCopy = (d) => {
        if (d) {
            let nd = { ...d }
            nd.referenca = ''
            nd._id = ''
            nd.isDraft = false
            itemSelect(nd)
            //console.log(d)
            // console.log(nd)

        }
    }
    const itemDelete = (id) => {
        //console.log('brisi')
        deleteRacun(id).then(data => {
            //console.log('brisano')

            const { deleted, message } = data
            console.log(deleted)
            if (message)
                showError('Napaka: ' + message)
            else {
                toast.success('Račun uspešno izbrisan', {
                    style: { padding: '16px', fontWeight: 600, }
                });
            }

        }).catch(e => {
            showError('Napaka: ' + e.message)
        }).finally(() => {
            getRacuniTop(10, 'draft').then(rac => {
                setTop10Draft(rac)

                //če brišemo teka k je izbran, prikazan
                if (racun && racun.id && racun.id == id)
                    handleClear();
            })
        })

    }

    useEffect(() => {
        if (racun.id && racun.isDraft) {
            setModeText('Osnutek')
            // racun.referenca = `SI00 ${stRacuna}`;
        }
        else if (racun.id && !racun.isDraft) {
            console.log('TAJE!')
            // console.log(racun)
            console.log(racun.stRacuna)

            setModeText('Račun ' + racun.stRacuna)
        }
        else if (!racun.id && racun.isDraft) {
            //to ne more bit
            setModeText('')
        }
        else if (!racun.id && !racun.isDraft) {
            setModeText('Novi račun')
        }
    }, [racun]);


    function pretvoriDatum2(datum) {
        const s = datum.split('.')

        if (s.length == 3 && s[2].length == 4)
            return `${s[2]}-${s[1]}-${s[0]}`;
        else {
            const datumObj = new Date(datum);
            const dan = datumObj.getDate().toString().padStart(2, '0');
            const mesec = (datumObj.getMonth() + 1).toString().padStart(2, '0');
            const leto = datumObj.getFullYear();

        }
    }

    function pretvoriDatum(datum) {
        const datumObj = new Date(datum);
        const dan = datumObj.getDate().toString().padStart(2, '0');
        const mesec = (datumObj.getMonth() + 1).toString().padStart(2, '0');
        const leto = datumObj.getFullYear();
        const ure = datumObj.getHours().toString().padStart(2, '0');
        const minute = datumObj.getMinutes().toString().padStart(2, '0');

        return `${dan}.${mesec}.${leto} ${ure}:${minute}`;
    }

    function df(datum) {
        var leto = datum.getFullYear();
        var mesec = datum.getMonth() + 1; // Prištej 1, ker je januar 0
        var dan = datum.getDate();

        // Dodaj vodilne ničle, če je potrebno (npr. '04' namesto '4')
        mesec = mesec < 10 ? '0' + mesec : mesec;
        dan = dan < 10 ? '0' + dan : dan;

        // Ustvari niz v obliki 'YYYY-MM-DD'
        var datumString = leto + '-' + mesec + '-' + dan;
        return datumString
    }

    return (
        <ThemeProvider theme={themeMain}>
            <Box component="div" sx={{ paddingBottom: '15px', paddingTop: '15px' }}>
                <IzberiPartnerja ref={izberiPartnerjaRef} />
                <IzberiBlago ref={izberiBlagoRef} />
                <Toaster />
                <SendToEmail ref={sendToEmailRef} />
                <Grid container spacing={2} >

                    {!miniBox && (
                    <Grid item xs={12}>
                        <Navbar />
                    </Grid>
                    )}
                    {!miniBox && (
                    <Grid container item md={3} xs={12}>
                        <CPaper xs={12} sx={{ width: '100%', boxShadow: 'rgb(173 173 173 / 20%) 0px 0px 28px 0px', padding: '1.2rem' }} >
                            <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Grid item sm={12} xs={12}>


                                    <div className='mojaGlava nadlistom'  >
                                        <span>potrjeni računi</span>
                                    </div>

                                    <List
                                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"

                                    >
                                        {top10Invoice.map((item, index) => {
                                            //const { naziv, opis, kolicina, cena, stopnjaDDV } = item;
                                            let partnerNaziv = ''
                                            if (item.partner && item.partner.naziv) partnerNaziv = item.partner.naziv
                                            return (
                                                <ListItem
                                                    key={index}
                                                    secondaryAction={
                                                        <Tooltip title="Kopiraj v nov račun" >
                                                            <IconButton edge="end" aria-label="comments" onClick={() => itemCopy(item)}>
                                                                <ContentCopyIcon fontSize="small" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton key={index} dense={true} selected={racun.id === item._id} onClick={() => itemSelect(item)} >
                                                        <ListItemIcon>
                                                            <MailIcon />
                                                        </ListItemIcon>
                                                        <ListItemText primary={partnerNaziv} secondary={'račun  ' + item.stRacuna} />
                                                    </ListItemButton>
                                                </ListItem>

                                            )
                                        })}

                                    </List>

                                </Grid>
                            </Grid>
                        </CPaper>
                    </Grid>
                    )}
                    <Grid container item md={miniBox? 12 : 6 } xs={12}>
                        <CPaper sx={{ boxShadow: miniBox? 'none' : 'rgb(161 161 161 / 90%) 0px 0px 28px 0px', padding: miniBox? '0.8rem' : '2rem' }} >
                            <div className='mojaGlava'>
                                <div style={{ width: '120px' }}>{modeText}</div>
                                <div style={{ visibility: racun.id && !racun.isDraft ? 'hidden' : 'visible' }} >{stRacuna}</div>
                                <div style={{ width: '120px' }}></div>
                                {!miniBox && (
                                <Tooltip title="Nov račun" >
                                    <Fab variant="extended" size="small" onClick={handleClear} aria-label="add" style={{ position: 'absolute', right: '-6px', top: '-8px' }}>
                                        <AddIcon />
                                    </Fab>
                                </Tooltip>
                                )}
                            </div>


                            <form id="form" onSubmit={handleSubmit}>
                                <Grid container item md={12} rowSpacing={3} columnSpacing={5} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>



                                    <Grid item md={6} sm={miniBox? 6 : 12 } xs={12} sx={{ paddingBottom: '10px' }}>
                                        <TextField onClick={handleAddPartner} variant='standard' size='medium' label="Stranka" multiline minRows={2} value={formatedPartner} spellCheck={false} fullWidth inputProps={{ style: { cursor: 'pointer', fontWeight: '600', fontSize: '0.85rem', paddingBottom: '7px', lineHeight: '1.7', letterSpacing: '0.4px' } }} />
                                    </Grid>
                                    <Grid item md={3} sm={miniBox? 3 : 12 } xs={12} sx={{ paddingBottom: '10px' }}>
                                        <TextField variant='standard' id='test' size='small' value={racun.datumIzdaje} label="Datum izdaje" InputProps={{ readOnly: (racun.id && !racun.isDraft) }} type="date" fullWidth onChange={(e) => setRacun({ ...racun, datumIzdaje: e.target.value })} required />
                                    </Grid>

                                    <Grid container item sm={12} xs={12} rowSpacing={2} columnSpacing={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                        <Grid item md={3} sm={miniBox? 3 : 6 } xs={12}>
                                            <TextField variant='standard' size='small' label="Datum opr. stor." InputProps={{ readOnly: (racun.id && !racun.isDraft) }} value={racun.datumOprStoritve} type="date" fullWidth onChange={(e) => setRacun({ ...racun, datumOprStoritve: e.target.value })} required />
                                        </Grid>
                                        <Grid item md={3} sm={miniBox? 3 : 6 } xs={12}>
                                            <TextField variant='standard' size='small' label="Rok plačila" InputProps={{ readOnly: (racun.id && !racun.isDraft) }} value={racun.rokPlacila} type="date" fullWidth onChange={(e) => setRacun({ ...racun, rokPlacila: e.target.value })} required />
                                        </Grid>
                                        <Grid item md={3} sm={miniBox? 3 : 6 } xs={12}>
                                            <TextField variant='standard' size='small' label="Številka naročila" InputProps={{ readOnly: (racun.id && !racun.isDraft) }} value={racun.stNarocila} fullWidth onChange={(e) => setRacun({ ...racun, stNarocila: e.target.value })} />
                                        </Grid>
                                        <Grid item md={3} sm={miniBox? 3 : 6 } xs={12}>
                                            <TextField variant='standard' size='small' label="Referenca" InputProps={{ readOnly: (racun.id && !racun.isDraft) }} value={racun.referenca || `SI00 ${stRacuna}`} fullWidth onChange={(e) => setRacun({ ...racun, referenca: e.target.value })} />
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <TextField variant='standard' size='small' label="Prosto besedilo" multiline value={racun.dodatenText} spellCheck={false} fullWidth inputProps={{ readOnly: (racun.id && !racun.isDraft), style: { marginLeft: '0', padding: '1px 0' } }} onChange={(e) => setRacun({ ...racun, dodatenText: e.target.value })} />
                                    </Grid>
                                </Grid>


                                <Grid container spacing={0.2} sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '3rem' }}>

                                    <Grid item sm={3} style={{ paddingTop: '0' }}>
                                        <Typography variant='caption' color={'#9d9d9d'} ml='14px'>Količina</Typography>
                                    </Grid>
                                    <Grid item sm={3} style={{ paddingTop: '0' }}>
                                        <Typography variant='caption' color={'#9d9d9d'} >Cena</Typography>
                                    </Grid>
                                    <Grid item sm={3} style={{ paddingTop: '0' }}>
                                        <Typography variant='caption' color={'#9d9d9d'} sx={{ paddingLeft: '3px' }}>DDV</Typography>
                                    </Grid>
                                    <Grid item sm={3} style={{ paddingTop: '0', paddingRight: '3px' }} >
                                        <Typography variant='caption' color={'#9d9d9d'} sx={{ paddingLeft: '3px' }}>Brez DDV</Typography>
                                    </Grid>
                                    {storitve.map((storitev, index) => {
                                        const { naziv, opis, kolicina, cena, stopnjaDDV } = storitev;
                                        return (
                                            <Grid item sm={12} xs={12} key={index} onClick={() => handleAdd(index)} sx={{ cursor: 'pointer' }}>
                                                <IzbranoBlago naziv={naziv} opis={opis} kolicina={kolicina} cena={cena} stopnjaDDV={stopnjaDDV} bg={index % 2 === 0 ? '#f1f1f152' : '#f1f1f1c7'} />
                                            </Grid>
                                        )
                                    })}
                                    <Grid item sm={12} xs={12} onClick={() => handleAdd(-1)} style={{ cursor: 'pointer', marginBottom: storitve && storitve.length > 0 ? '40px' : '40px', marginTop: storitve && storitve.length > 0 ? '0' : '6px' }} >
                                        <div className='dodajIzdelekStoritev' style={{ backgroundColor: storitve && storitve.length % 2 === 0 ? '#f1f1f152' : '#f1f1f1c7' }}>

                                            <span style={{ color: racun.id && !racun.isDraft ? '#ccc' : 'inherit' }}>Dodaj izdelek / storitev</span> <span>&nbsp;</span>
                                        </div>
                                    </Grid>



                                    <Grid item sm={12} xs={12} >

                                        <Divider variant="fullWidth" sx={{ borderBottomWidth: 2, borderColor: '#ADD8E6', marginTop: '0' }} />
                                    </Grid>
                                </Grid>
                            </form>
                            <Sum />
                            <div className='bottomBtns'>



                                <div style={{ display: !racun.id || racun.isDraft ? 'none' : 'block' }}>
                                    <Tooltip title="Pošlji na email"
                                        PopperProps={{
                                            modifiers: [
                                                {
                                                    name: "offset",
                                                    options: {
                                                        offset: [0, -10],
                                                    },
                                                },
                                            ],
                                        }}
                                    >
                                        <IconButton color="primary" size='medium' onClick={() => sendToEmailRef.current.handleClickOpen(racun.id)} >
                                            <ForwardToInboxOutlinedIcon />
                                        </IconButton >
                                    </Tooltip>
                                    <Tooltip title="Prenesi PDF na računalnik"
                                        PopperProps={{
                                            modifiers: [
                                                {
                                                    name: "offset",
                                                    options: {
                                                        offset: [0, -10],
                                                    },
                                                },
                                            ],
                                        }}
                                    >
                                        <IconButton color="primary" size='medium' style={{ marginLeft: '20px' }} onClick={() => getPdf(racun.stRacuna, racun.creator, racun.id, true)} >
                                            <DownloadIcon />
                                        </IconButton >
                                    </Tooltip>
                                    <Tooltip title="Odpri PDF"
                                        PopperProps={{
                                            modifiers: [
                                                {
                                                    name: "offset",
                                                    options: {
                                                        offset: [0, -10],
                                                    },
                                                },
                                            ],
                                        }}
                                    >
                                        <IconButton color="primary" size='medium' style={{ marginLeft: '20px' }} onClick={() => getPdf(racun.stRacuna, racun.creator, racun.id, false)} >
                                            <PictureInPictureIcon />
                                        </IconButton >
                                    </Tooltip>
                                </div>

                                <div style={{ position: 'relative', alignItems: 'flex-start', display: racun.id && !racun.isDraft ? 'none' : 'flex' }}  >
                                    <Button variant='contained' color="primary" size='medium' onClick={() => saveInvoice(false)} >
                                        POTRDI
                                    </Button>
                                    {!miniBox && (
                                    <div style={{ color: '#6D6D6D', position: 'absolute', left: '115px', top: '-10px', fontSize: '0.75rem' }}>
                                        <FormControlLabel labelPlacement="end" control={<Checkbox size="small" checked={racun.periodni} onChange={(e) => setRacun({ ...racun, periodni: e.target.checked })} style={{ paddingBottom: '6px' }} />} label={<span style={{ fontSize: '0.75rem', textWrap: 'nowrap' }}>{"Pripravi kopijo naslednji mesec"}</span>} />
                                        <div style={{ fontWeight: '600', textWrap: 'nowrap', visibility: racun.periodni ? 'visible' : 'hidden' }}>Dne {periodicniDatum} se bo ustvaril osnutek</div>
                                    </div>
                                    )}
                                </div>

                                {!miniBox && (
                                <Tooltip title="Shrani kot osnutek"
                                    PopperProps={{
                                        modifiers: [
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [0, -10],
                                                },
                                            },
                                        ],
                                    }}
                                >
                                    <Button tooltip='test 34' variant='contained' color="secondary" size='medium' style={{ marginLeft: '25px' }} onClick={() => saveInvoice(true)}
                                        sx={{ visibility: racun.id ? 'hidden' : 'visible' }} >
                                        SHRANI
                                    </Button>
                                </Tooltip>
                                )}

                            </div>
                        </CPaper>
                    </Grid>
                    {!miniBox && (
                    <Grid container item md={3} xs={12}>
                        <CPaper xs={12} sx={{ width: '100%', boxShadow: 'rgb(173 173 173 / 20%) 0px 0px 28px 0px', padding: '1.2rem',position:'relative' }} >
                            <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Grid item sm={12} xs={12}>
                                    <div className='mojaGlava nadlistom'  ><span>Shranjeni računi</span></div>
                                    <List
                                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"

                                    >
                                        {top10Draft.map((item, index) => {
                                            //const { naziv, opis, kolicina, cena, stopnjaDDV } = item;
                                            let partnerNaziv = ''
                                            if (item.partner && item.partner.naziv) partnerNaziv = item.partner.naziv
                                            return (

                                                <ListItem
                                                    key={index}
                                                    secondaryAction={
                                                        <Tooltip title="izbriši osnutek" >
                                                            <IconButton edge="end" aria-label="comments" onClick={() => itemDelete(item._id)}>
                                                                <DeleteOutlineIcon fontSize="medium" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton dense={true} selected={racun.id === item._id} onClick={() => itemSelect(item)} >
                                                        <ListItemIcon>
                                                            <DraftsIcon style={{display: item.semPeriodni? 'none': 'inline-block'}}/>
                                                            <AutoModeIcon style={{display: item.semPeriodni? 'inline-block': 'none'}} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={partnerNaziv} secondary={item.semPeriodni? item.datumIzdaje : pretvoriDatum(item.updatedAt)} />
                                                    </ListItemButton>
                                                </ListItem>

                                            )
                                        })}

                                    </List>

                                </Grid>
                            </Grid>


      <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0 , paddingBottom: '20px'}} >
        <BottomNavigation
          showLabels
          value={valueBottomNav}
          onChange={(event, newValue) => {
            setValueBottomNav(newValue);
          }}
        >
          <BottomNavigationAction label="Osnutki" icon={<DraftsIcon />} />
          <BottomNavigationAction label="Prihajajoči" icon={<AutoModeIcon />} /> 
        </BottomNavigation>
      </div>



                        </CPaper>
                    </Grid>
                     )}   

                </Grid>



            </Box >
        </ThemeProvider>
    )
}

export default Izdaja

/*    
, display: storitve && storitve.length > 0 ? 'block' : 'none'
<Button variant='outlined' size='small' style={{ fontSize: '0.75rem', paddingTop: '5px' }} color="primary" onClick={() => handleAdd(-1)} sx={{}}>Dodaj izdelek / storitev</Button>
 */
/*
 <Grid container spacing={0.2} sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '39px' }}>
                                    {top10Draft.map((item, index) => {
                                        //const { naziv, opis, kolicina, cena, stopnjaDDV } = item;
                                        let partnerNaziv = ''
                                        if (item.partner && item.partner.naziv) partnerNaziv = item.partner.naziv
                                        return (
                                            <Grid item sm={12} xs={12} key={index} className='top10Item' onClick={() => handleAdd(index)} sx={{ cursor: 'pointer', borderBottom: '1px solid #ccc', marginBottom: '6px', paddingBottom: '6px' }}>
                                                <Grid container item>
                                                    <Grid item sm={6} >
                                                        <Typography variant="caption" >{pretvoriDatum(item.createdAt)}</Typography>
                                                    </Grid>
                                                    <Grid item sm={6}  >
                                                        <Typography variant="caption" >{partnerNaziv}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Grid> */

/*

<CPaper elevation={2} sx={{ width: 'fit-content', padding: '1rem' }} className="test" onClick={handleAddPartner} >
                                        <ThemeProvider theme={PartenerTexts}>
                                            <Grid item sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                                <Typography variant="body2" >Partner:</Typography>
                                                <Typography variant="body1" >{selectedPartner.naziv}</Typography>
                                            </Grid>
                                            <Grid item sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                                <Typography variant="body2" >Naslov:</Typography>
                                                <Typography variant="body1" >{selectedPartner.naziv === '' ? '' : `${selectedPartner.naslov}, ${selectedPartner.posta} ${selectedPartner.mesto}`}</Typography>
                                            </Grid>
                                            <Grid item sm={12} xs={12} sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                                <Typography variant="body2" >Davčna številka:</Typography>
                                                <Typography variant="body1" >{selectedPartner.davcnaStevilka}</Typography>
                                            </Grid>
                                        </ThemeProvider>
                                    </CPaper>

*/
import React, { createContext, useContext, useState } from 'react';
import * as moment from 'moment';
const StateContext = createContext();

export const ContextProvider = ({ children }) => {
    const [storitve, setStoritve] = useState([]); //array za vse dodane storitve(/)
    const [blago, setBlago] = useState([]); //array za vsa dodana blaga(/dodaj-blago)
    const [storitev, setStoritev] = useState({ id:storitve.length, naziv:'', kolicina:'', enota:'', cena:'', stopnjaDDV:'', opis:'' });//array za eno storitev ki jo dodamo v storitve(/dodaj-storitev)
    const [racunContext, setRacunContext] = useState({ stRacuna:'', partner:{}, datumIzdaje: moment().format('YYYY-MM-DD'), datumOprStoritve: moment().format('YYYY-MM-DD'), rokPlacila: moment().format('YYYY-MM-DD'), stNarocila:'', storitve:[], skupajZaPlacilo:'', skupajBrezDDV:'', referenca: '', dodatenText:'', periodni: false});
    const [finnalRacun, setFinnalRacun] = useState({});
    const [partnerji, setPartnerji] = useState([]);
    const [diffDDVs, setDiffDDVs] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState({ naziv:'', naslov:'', mesto:'', posta:'', drzava:'',davcnaStevilka:'' });
    const [skupajBrezDDV, setSkupajBrezDDV] = useState('');
    const [skupajDDV, setSkupajDDV] = useState('');
    //const [userId, setUserId] = useState('');
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [zneski, setZneski] = useState({ znesekNeto: 0, znesekBruto: 0, ddvPovzetek: {} });

    return (
        <StateContext.Provider value={{ user, setUser, diffDDVs, setDiffDDVs, selectedPartner, setSelectedPartner, partnerji, setPartnerji, storitve, setStoritve, storitev, setStoritev, blago, setBlago, racunContext, setRacunContext, skupajBrezDDV, setSkupajBrezDDV, skupajDDV, setSkupajDDV, setFinnalRacun, finnalRacun, zneski, setZneski }}>
            {children}
        </StateContext.Provider>
    )
};

export const useStateContext = () => useContext(StateContext);